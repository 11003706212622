.toolbar {
    padding: 10px;
    width: 100%;
    border-bottom: thin white solid;

    button {
        margin: 5px;
        padding: 15px 20px;
        background-color: white;
        color: black;
        font-weight: bold;
        cursor: pointer;
    }
}
