@keyframes action-pulse {
    33%, 66% {
      transform: scale(1.2);
    }
  }

  .actionButton {
    animation: action-pulse 1s ease-in-out infinite reverse;
  }

  ion-avatar {
    cursor: pointer;
  }

  .playerMissionReport {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      flex: 1;
      list-style: none;
      border: solid thin rgb(187, 181, 181);
      height: 35px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}

.playerChipWell {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ion-chip {
    min-width: 45%;
    max-width: 45%;
    white-space: nowrap;
    display: flex;

    ion-label {
      flex: 1;
    }
  }
}

.picker-toolbar {
  justify-content: space-between !important;
}

.redacted {
  position: relative;

  &::after {
    content: 'REDACTED';
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    width: 110;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: red;
    border: thick red solid;
  }
}

.DirectoryCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ion-card:last-child {
    margin-bottom: 60px;
  }
}